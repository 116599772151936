<template>
  <v-select
    v-model="inputVal"
    :label="label"
    :items="data"
     item-text="name"
     item-value="key"
  ></v-select>
</template>

<script>
export default {
  props: ["value","data","label"],
  components: {
    //
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data: () => ({
    
  }),
  created() {
    //
  },
  mounted() {
  },
  methods: {
    //
  },
};
</script>